import React, { useEffect, useRef, useState } from 'react'

import { useRecoilValue } from 'recoil'

import { currentWellAtom } from 'atoms'
import WellDepthTimeChart from 'components/WellPages/DailyReportsPages/CarouselItems/WellDepthTimeChart'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { createChartDataMultiWell } from 'components/common/depthVtimeFunctions'
import { Skeleton, Box } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DepthTimeCard = () => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const { getUnitsText } = useUnits()
  const { getChartBackColor } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/kpi/depthVdays',
  })

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    fetchData()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (currentWell.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: currentWell,
    }

    payload.phaseFilter = ''

    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      if (dataResponse?.data) {
        setData(dataResponse.data)
      } else {
        setData([])
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        minHeight: '80px',
      }}>
      {Array.isArray(data) && data.length > 0 ? (
        <WellDepthTimeChart
          xAxisTitle={'Days'}
          yAxisTitle={`Depth (${getUnitsText(UNITS_FOR.Depth)})`}
          wellData={createChartDataMultiWell(data)}
          rawData={data}
        />
      ) : (
        <Skeleton variant='rectangular' width='100%' height='100%' animation='wave' />
      )}
    </Box>
  )
}

export default DepthTimeCard
