import React, { useState } from 'react'
import { Box, Drawer, Tooltip, List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material'
import { styled } from '@mui/styles'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Icon as Iconify } from '@iconify/react'

import profileIcon from 'assets/EngineeringIcons/Profile.png'
import casingIconGray from 'assets/wellScreenIcons/schematicGrey.png'
import casingIconBlue from 'assets/wellScreenIcons/schematicBlue.png'
import WitsGreyIcon from 'assets/wellScreenIcons/edr-grey.png'
import WitsActiveIcon from 'assets/wellScreenIcons/edr-blue.png'
import broomStickIcon from 'assets/EngineeringIcons/Broomstick.png'
import hydraulicsIcon from 'assets/EngineeringDashboardIcons/Hydraulics.png'
import pumpPressureIcon from 'assets/EngineeringDashboardIcons/Pump Pressure.png'
import ecdIcon from 'assets/EngineeringDashboardIcons/ECD.png'
import ecdSnapShotIcon from 'assets/EngineeringDashboardIcons/ECDss.png'
import surgeAndSwabIcon from 'assets/EngineeringDashboardIcons/Surge Swab.png'
import tripSpeedIcon from 'assets/EngineeringDashboardIcons/Tripping Speed.png'
import minFlowIcon from 'assets/EngineeringDashboardIcons/Minimum Flow.png'
import drillersHookloadIcon from 'assets/EngineeringDashboardIcons/Drillers Hookloads.png'
import frictionFactorsIcon from 'assets/EngineeringDashboardIcons/Calculated Friction Factors.png'
import drillersTorqueIcon from 'assets/EngineeringDashboardIcons/Drillers Torques.png'
import onBtmTensionIcon from 'assets/EngineeringDashboardIcons/On Btm Tension Snapshot.png'
import offBtmTensionIcon from 'assets/EngineeringDashboardIcons/Off Btm Tension Snapshot.png'
import sideForceIcon from 'assets/EngineeringDashboardIcons/Sideforce.png'
import apparentWobIcon from 'assets/EngineeringDashboardIcons/Apparent WOB.png'
import pipeTwistIcon from 'assets/EngineeringDashboardIcons/Pipe Twist.png'
import pipeStretchIcon from 'assets/EngineeringDashboardIcons/Pipe Stretch.png'
import wellControlIcon from 'assets/EngineeringDashboardIcons/Well Control.png'
import kickToleranceIcon from 'assets/EngineeringDashboardIcons/Kick Tolerance.png'
import bhaAnalysisIcon from 'assets/EngineeringDashboardIcons/BHA Analysis.png'
import bhaAnalysis3dIcon from 'assets/EngineeringDashboardIcons/3D Charts.png'
import CasingStandOffIcon from 'assets/EngineeringDashboardIcons/Casing Standoff.png'
import CentralizerStandOffIcon from 'assets/EngineeringDashboardIcons/Centralizer Standoff.png'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const DRAWERWIDE = 220
export const DRAWERSLIM = 70
const ICON_ACTIVE_COLOR = '#34c9eb'
const ICON_INACTIVE_COLOR = 'rgb(192, 192, 192)'

const EngineeringToolbar = ({
  toolbarExpanded,
  toggleToolbarExpanded,
  selectedChart,
  setSelectedChart,
  onXlsxExport,
  onPdfExport,
  toggleCasing,
  toggleLegend,
  showLegend,
  showCasing,
  toggleShadebucking,
  shadeBuckling,
  toggleSlidesheetParams,
  toggleWitsData,
  toggleShowSelectBhas,
  toggleShowSelectWitsDepthRange,
  showSlideSheetParams,
  showWitsData,
  getErrors,
  getWarnings,
  chartType,
  setShowEngineeringReportSettings,
}) => {
  const { theme, getChartBackColor, getActionBarColor, getTextColor } = useInnovaTheme()

  const StyledDrawer = styled(Drawer)(({ muiTheme, drawerwidth }) => ({
    '& .MuiDrawer-paper': {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: getActionBarColor(),
      width: drawerwidth,
      '&::-webkit-scrollbar': {
        width: '10px',
        backgroundColor: getChartBackColor(),
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
        WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme === 'dark' ? '#133f60' : '#A0A0A0',
      },
    },
  }))

  const [expandedSubMenus, setExpandedSubMenus] = useState({
    hydraulics: false,
    torqueAndDrag: false,
    bhaAnalysis: false,
    wellControl: false,
    export: false,
    controls: false,
    drillingData: false,
    casingStandOff: false,
  })

  const toggleSubeMenuExpanded = (subMenu) => {
    if (!expandedSubMenus.hasOwnProperty(subMenu)) {
      console.warn(`Invalid submenu: ${subMenu}`)
      return
    }

    setExpandedSubMenus({ ...expandedSubMenus, [subMenu]: !expandedSubMenus[subMenu] })
  }

  const setChart = (chart) => {
    setSelectedChart(chart)
  }

  const MENU_ITEMS = [
    {
      text: 'Warnings',
      icon: 'material-symbols:error',
      hide: getWarnings()?.length ? false : true,
      color: 'orange',
    },
    {
      text: 'Errors',
      icon: 'material-symbols:warning',
      hide: getErrors()?.length ? false : true,
      color: 'red',
    },
    {
      text: 'Hydraulics',
      image: hydraulicsIcon,
      children: [
        {
          text: 'Pump Pressure',
          image: pumpPressureIcon,
          tag: 'pumpPressure',
          onClick: () => setChart('pumpPressure'),
        },
        {
          text: 'ECD',
          image: ecdIcon,
          tag: 'ecd',
          onClick: () => setChart('ecd'),
        },
        {
          text: 'ECD Snapshot',
          image: ecdSnapShotIcon,
          tag: 'ecdSnapshot',
          onClick: () => setChart('ecdSnapshot'),
        },
        {
          text: 'Surge and Swab',
          image: surgeAndSwabIcon,
          tag: 'surgeAndSwab',
          onClick: () => setChart('surgeAndSwab'),
        },
        {
          text: 'Tripping Speeds',
          image: tripSpeedIcon,
          tag: 'minMaxTripSpeed',
          onClick: () => setChart('minMaxTripSpeed'),
        },
        {
          text: 'Minimum Flow',
          image: minFlowIcon,
          tag: 'minFlow',
          onClick: () => setChart('minFlow'),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('hydraulics'),
      isExpanded: expandedSubMenus?.hydraulics,
    },
    {
      text: 'Torque & Drag',
      image: broomStickIcon,
      children: [
        {
          text: 'Drillers Hookloads',
          image: drillersHookloadIcon,
          tag: 'drillersHookloads',
          onClick: () => setChart('drillersHookloads'),
        },
        {
          text: 'Calculated Friction Factors',
          image: frictionFactorsIcon,
          tag: 'calculatedFrictionFactors',
          onClick: () => setChart('calculatedFrictionFactors'),
        },
        {
          text: 'Drillers Torques',
          image: drillersTorqueIcon,
          tag: 'drillersTorques',
          onClick: () => setChart('drillersTorques'),
        },
        {
          text: 'On Btm Tension Snapshot',
          image: onBtmTensionIcon,
          tag: 'tensionSnapshot',
          onClick: () => setChart('tensionSnapshot'),
        },
        {
          text: 'Off Btm Tension Snapshot',
          image: offBtmTensionIcon,
          tag: 'tensionOffBtmSnapshot',
          onClick: () => setChart('tensionOffBtmSnapshot'),
        },
        {
          text: 'Sideforce',
          image: sideForceIcon,
          tag: 'sideForce',
          onClick: () => setChart('sideForce'),
        },
        {
          text: 'Apparent WOB',
          image: apparentWobIcon,
          tag: 'appWob',
          onClick: () => setChart('appWob'),
        },
        {
          text: 'Pipe Twist',
          image: pipeTwistIcon,
          tag: 'twist',
          onClick: () => setChart('wraps'),
        },
        {
          text: 'Pipe Stretch',
          image: pipeStretchIcon,
          tag: 'stretch',
          onClick: () => setChart('stretch'),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('torqueAndDrag'),
      isExpanded: expandedSubMenus?.torqueAndDrag,
    },
    {
      text: 'Well Control',
      image: wellControlIcon,
      children: [
        {
          text: 'Kick Tolerance',
          image: kickToleranceIcon,
          tag: 'kickTolerance',
          onClick: () => setChart('kickTolerance'),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('wellControl'),
      isExpanded: expandedSubMenus?.wellControl,
    },
    {
      text: 'Bha Analysis',
      image: bhaAnalysisIcon,
      children: [
        {
          text: '2D Line Charts',
          image: profileIcon,
          tag: 'forcesAndDeflections',
          onClick: () => setChart('forcesAndDeflections'),
        },
        {
          text: '2D Contour Plots',
          icon: 'gis:contour-map',
          tag: 'forcesAndDeflectionsContour',
          onClick: () => setChart('forcesAndDeflectionsContour'),
        },
        {
          text: '3D Charts',
          image: bhaAnalysis3dIcon,
          tag: 'forcesAndDeflections3d',
          onClick: () => setChart('forcesAndDeflections3d'),
        },
        {
          text: 'Directional Tendency Chart',
          icon: 'flat-color-icons:line-chart',
          tag: 'directionalTendencyChart',
          onClick: () => setChart('directionalTendencyChart'),
        },
        {
          text: 'Critical RPM Chart',
          icon: 'marketeq:chart-column-grow',
          tag: 'criticalRpmChart',
          onClick: () => setChart('criticalRpmChart'),
        },
        {
          text: 'BHA Mode Shapes Chart',
          icon: 'mdi:waveform',
          tag: 'modeShapesChart',
          onClick: () => setChart('modeShapesChart'),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('bhaAnalysis'),
      isExpanded: expandedSubMenus?.bhaAnalysis,
    },
    {
      text: 'Casing Stand Off',
      image: CentralizerStandOffIcon,
      children: [
        {
          text: 'Stand off',
          image: CasingStandOffIcon,
          tag: 'casingStandOff',
          onClick: () => setChart('casingStandOff'),
        },
        {
          text: 'Hookloads',
          image: drillersHookloadIcon,
          tag: 'standOffHookloads',
          onClick: () => setChart('standOffHookloads'),
        },
        {
          text: 'Torque',
          image: drillersTorqueIcon,
          tag: 'standOffTorque',
          onClick: () => setChart('standOffTorque'),
        },
        {
          text: 'Centralizer Spacing',
          image: pipeStretchIcon,
          tag: 'standOffCentSpacing',
          onClick: () => setChart('standOffCentSpacing'),
        },
        {
          text: 'DLS',
          icon: 'mdi:graph-bell-curve-cumulative',
          tag: 'standOffDLS',
          onClick: () => setChart('standOffDLS'),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('casingStandOff'),
      isExpanded: expandedSubMenus?.casingStandOff,
    },
    {
      text: 'Chart Controls',
      icon: 'material-symbols:settings-outline',
      children: [
        {
          text: 'Casing Symbols',
          image: showCasing ? casingIconBlue : casingIconGray,
          onClick: () => toggleCasing(),
        },
        {
          text: 'Chart Legend',
          icon: 'gala:chart',
          color: showLegend ? ICON_ACTIVE_COLOR : ICON_INACTIVE_COLOR,
          onClick: () => toggleLegend(),
        },
        {
          text: selectedChart !== 'kickTolerance' ? 'Shade Buckling' : 'Shade Limits',
          icon: 'material-symbols:area-chart',
          color: shadeBuckling ? ICON_ACTIVE_COLOR : ICON_INACTIVE_COLOR,
          onClick: () => toggleShadebucking(),
          hide:
            selectedChart !== 'tensionSnapshot' &&
            selectedChart !== 'tensionOffBtmSnapshot' &&
            selectedChart !== 'drillersHookloads' &&
            selectedChart !== 'kickTolerance',
        },
      ],
      onClick: () => toggleSubeMenuExpanded('controls'),
      isExpanded: expandedSubMenus?.controls,
    },
    {
      text: 'Export',
      icon: 'material-symbols:print',
      children: [
        {
          text: 'PDF Print Settings',
          icon: 'fluent-mdl2:text-document-settings',
          color: 'blue',
          onClick: () => setShowEngineeringReportSettings(true),
        }, 
        {
          text: 'Excel',
          icon: 'icon-park-outline:excel',
          color: 'green',
          onClick: () => onXlsxExport(),
        },
        {
          text: 'PDF',
          icon: 'teenyicons:pdf-solid',
          color: '#f30f31',
          onClick: () => onPdfExport(),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('export'),
      isExpanded: expandedSubMenus?.export,
    },
    {
      text: 'Drilling Data',
      image: WitsGreyIcon,
      children: [
        {
          text: 'Show Slide Sheet Params',
          icon: 'iconamoon:options',
          color: showSlideSheetParams ? ICON_ACTIVE_COLOR : ICON_INACTIVE_COLOR,
          onClick: () => toggleSlidesheetParams(),
        },
        {
          text: 'Show WITS Data',
          image: showWitsData ? WitsActiveIcon : WitsGreyIcon,
          onClick: () => toggleWitsData(),
        },
        {
          text: 'Select BHAs',
          icon: 'icon-park-outline:list',
          color: 'gray',
          onClick: () => toggleShowSelectBhas(),
        },
        {
          text: 'Select WITS Depth Range',
          icon: 'iconoir:depth',
          color: 'gray',
          onClick: () => toggleShowSelectWitsDepthRange(),
        },
      ],
      onClick: () => toggleSubeMenuExpanded('drillingData'),
      isExpanded: expandedSubMenus?.drillingData,
      hide:
        selectedChart !== 'pumpPressure' &&
        selectedChart !== 'drillersTorques' &&
        selectedChart !== 'drillersHookloads' &&
        selectedChart !== 'minFlow' &&
        selectedChart !== 'appWob' &&
        selectedChart !== 'criticalRpmChart' &&
        selectedChart !== 'directionalTendencyChart',
    },
  ]

  const ExpandContractListItem = () => {
    return (
      <ListItemButton onClick={() => toggleToolbarExpanded()}>
        <Tooltip
          title={toolbarExpanded ? 'collapse' : 'expand'}
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <ListItemIcon
            sx={{
              color: getTextColor(),
              width: '35px',
              minWidth: '35px',
            }}>
            {toolbarExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </ListItemIcon>
        </Tooltip>
      </ListItemButton>
    )
  }

  const getToolTipText = (text) => {
    if (!text) return ''
    if (text !== 'Warnings' && text !== 'Errors') return text

    let warnings = text === 'Errors' ? getErrors() : getWarnings()
    return (
      <table>
        <thead style={{ color: 'red' }}>
          <tr>
            <th>
              {warnings.length > 0
                ? `${chartType} ${text === 'Errors' ? 'errors' : 'warnings'}`
                : `No ${text === 'Errors' ? 'errors' : 'warnings'}`}
            </th>
          </tr>
        </thead>
        <tbody style={{ color: 'white' }}>
          {warnings.map((warning, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {idx + 1} - {warning}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const ToolBarItem = ({ menuItem, isNestedItem }) => {
    if (menuItem?.hide) return null
    let isItemSelected = selectedChart === menuItem?.tag
    let backgroundColor = isItemSelected ? '#192734' : getActionBarColor()
    if (theme !== 'dark') {
      backgroundColor = isItemSelected ? '#4c85c280' : isNestedItem ? '#FFF' : getActionBarColor()
    }

    return (
      <React.Fragment>
        <ListItemButton
          onClick={menuItem.onClick ? menuItem.onClick : () => {}}
          sx={{
            backgroundColor: backgroundColor,
          }}>
          <Tooltip
            title={getToolTipText(menuItem.text)}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, toolbarExpanded ? 80 : 20],
                    },
                  },
                ],
              },
            }}
            placement='right'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}>
              {menuItem?.image ? (
                <img alt={'img'} src={menuItem.image} style={{ height: 30, width: 30 }} />
              ) : (
                <Iconify
                  icon={menuItem?.icon}
                  style={{ color: menuItem?.color || ICON_INACTIVE_COLOR, height: 30, width: 30 }}
                />
              )}
              {toolbarExpanded ? (
                <ListItemText
                  primary={menuItem?.text}
                  sx={{
                    '& .MuiTypography-root': {
                      color: ICON_INACTIVE_COLOR,
                      fontSize: '14px',
                      fontFamily: 'Roboto',
                      fontWeight: 'bold',
                      marginLeft: '10px',
                    },
                  }}
                />
              ) : null}
            </Box>
          </Tooltip>
        </ListItemButton>
        <Collapse in={menuItem?.isExpanded} timeout='auto' unmountOnExit>
          <List
            sx={{
              marginLeft: toolbarExpanded ? '16px' : '0px',
              border: '1px solid',
              borderColor: ICON_ACTIVE_COLOR,
              backgroundColor: theme === 'dark' ? 'rgba(8,8,8)' : '#FFF',
            }}>
            {Array.isArray(menuItem?.children)
              ? menuItem.children.map((childItem, index) => (
                  <ToolBarItem key={index} menuItem={childItem} isNestedItem={true} />
                ))
              : null}
          </List>
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <StyledDrawer variant='permanent' drawerwidth={toolbarExpanded ? DRAWERWIDE : DRAWERSLIM}>
      <List>
        <ExpandContractListItem />
        {MENU_ITEMS.map((menuItem, index) => (
          <ToolBarItem key={index} menuItem={menuItem} />
        ))}
      </List>
    </StyledDrawer>
  )
}

export default EngineeringToolbar
