import { useEffect, useState, useRef, useCallback } from 'react'

import useAxiosGzip from '../useAxiosGzip'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, selectedEngineeringCaseAtom, userUserRoleAtom } from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { appColors } from 'utils'
import html2canvas from 'html2canvas'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols, replaceEscapeSymbols, numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from '../useInnovaAxios'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useBhaImages from '../useBhaImages'
import { getItemFromLS } from 'utils/localStorage'

function useTorqueAndDrag() {
  const _isMounted = useRef(true)
  const tndData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const { getUnitsText } = useUnits()
  const userRole = useRecoilValue(userUserRoleAtom)
  const [logos, setLogos] = useState([])
  const { user } = useInnovaAuth()
  const bhaImages = useBhaImages()

  const getData = useAxiosGzip({
    url: '/engineering/getTorqueAndDragGz',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const fetchTnd = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (!selectedEngCase) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getData({ wellName: currentWellRef.current, engCase: selectedEngCase?.recordId })
    if (_isMounted.current) {
      setLoading(false)
      tndData.current = response?.data ? response.data : null
    }

    isLoading.current = false

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: tndData.current.jobDetails?.operator })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']
    let cyanPalette = ['#49ecf5', '#226f73']
    let purplePalette = ['#e300b2', '#bc6bdb']
    let yellowPalette = ['#fffb05', '#ffa505']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    if (pallete === 'cyan') {
      startColor = cyanPalette[0]
      endColor = cyanPalette[1]
    }

    if (pallete === 'purple') {
      startColor = purplePalette[0]
      endColor = purplePalette[1]
    }

    if (pallete === 'yellow') {
      startColor = yellowPalette[0]
      endColor = yellowPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  const getSeriesValues = (data) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      output.push({
        x: pt.hasOwnProperty('data') ? pt.data : 0,
        y: pt.depth,
      })
    })

    return output
  }

  const getGeometryAnnotations = () => {
    if (!tndData.current) return null
    if (!tndData.current.hasOwnProperty('geometry')) return null
    if (!Array.isArray(tndData.current.geometry?.geometry)) return null
    if (!tndData.current.hasOwnProperty('drillersOutput')) return null
    if (!tndData.current.drillersOutput.hasOwnProperty('tripInWeight')) return null
    if (!Array.isArray(tndData.current.drillersOutput.tripInWeight)) return null

    let maxDepth = 0
    if (tndData.current.drillersOutput.tripInWeight > 0) {
      let curSeries = tndData.current.drillersOutput.tripInWeight[0]
      if (Array.isArray(curSeries.data)) {
        maxDepth = curSeries.data[curSeries.data.length - 1].depth
      }
    }

    const annotations = {}
    for (let i = 0; i < tndData.current.geometry?.geometry.length; i++) {
      if (tndData.current.geometry?.geometry[i].md > maxDepth) continue

      annotations[`geometry` + (i + 1)] = {
        type: 'line',
        value: tndData.current.geometry?.geometry[i].md,
        scaleID: 'y',
        borderColor: appColors.itemTextColor,
        borderDash: [10, 4],
        borderDashOffset: 5,
        borderWidth: 1,
        lineTension: 0.8,
        label: {
          content: `${tndData.current.geometry?.geometry[i].od} ${tndData.current.geometry?.geometry[i].compType}`,
          enabled: true,
          backgroundColor: 'transparent',
          position: 'start',
          color: appColors.itemTextColor,
          font: { weight: 'normal' },
          yAdjust: -5,
          display: true,
        },
      }
    }
    return annotations
  }

  const getDrillersHookloadsData = (shadeBuckling) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripInWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripOutWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('rotatingOffBottomWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('minBuckling')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripInWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripOutWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.rotatingOffBottomWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.minBuckling)) return chartData
    if (tndData.current.drillersOutput.tripInWeight.length === 0) return chartData
    if (tndData.current.drillersOutput.tripOutWeight.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.tripOutWeight.length; i++) {
      let curSeries = tndData.current.drillersOutput.tripOutWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, tndData.current.drillersOutput.tripOutWeight.length),
        backgroundColor: interpChartColor('blue', i, tndData.current.drillersOutput.tripOutWeight.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.rotatingOffBottomWeight.length; i++) {
      let curSeries = tndData.current.drillersOutput.rotatingOffBottomWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.drillersOutput.rotatingOffBottomWeight.length),
        backgroundColor: interpChartColor('green', i, tndData.current.drillersOutput.rotatingOffBottomWeight.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.tripInWeight.length; i++) {
      let curSeries = tndData.current.drillersOutput.tripInWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.tripInWeight.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.tripInWeight.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.minBuckling.length; i++) {
      let curSeries = tndData.current.drillersOutput.minBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('purple', i, tndData.current.drillersOutput.minBuckling.length),
        backgroundColor: interpChartColor('purple', i, tndData.current.drillersOutput.minBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 0, 0)' : null,
      })
    }

    return chartData
  }

  const getDrillersTorquesData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('offBottomTorque')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('onBottomTorque')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.offBottomTorque)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.onBottomTorque)) return chartData
    if (tndData.current.drillersOutput.offBottomTorque.length === 0) return chartData
    if (tndData.current.drillersOutput.onBottomTorque.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.offBottomTorque.length; i++) {
      let curSeries = tndData.current.drillersOutput.offBottomTorque[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, tndData.current.drillersOutput.offBottomTorque.length),
        backgroundColor: interpChartColor('blue', i, tndData.current.drillersOutput.offBottomTorque.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.onBottomTorque.length; i++) {
      let curSeries = tndData.current.drillersOutput.onBottomTorque[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.onBottomTorque.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.onBottomTorque.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getTensionSnapshotData = (shadeBuckling) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('slidingTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('rotatingOnBottomTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sinBuckling')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('helicalBuckling')) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.slidingTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.rotatingOnBottomTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sinBuckling)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.helicalBuckling)) return chartData
    if (tndData.current.snapShotOutput.slidingTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.rotatingOnBottomTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.sinBuckling.length === 0) return chartData
    if (tndData.current.snapShotOutput.helicalBuckling.length === 0) return chartData

    for (let i = 0; i < tndData.current.snapShotOutput.slidingTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.slidingTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.snapShotOutput.slidingTension.length),
        backgroundColor: interpChartColor('red', i, tndData.current.snapShotOutput.slidingTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.rotatingOnBottomTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.rotatingOnBottomTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOnBottomTension.length),
        backgroundColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOnBottomTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sinBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sinBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        backgroundColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 255, 0)' : null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.helicalBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.helicalBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        backgroundColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 0, 0)' : null,
      })
    }

    return chartData
  }

  const getTensionOffBtmSnapshotData = (shadeBuckling) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('tripInTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('rotatingOffBottomTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('tripOutTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sinBuckling')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('helicalBuckling')) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.tripInTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.rotatingOffBottomTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.tripOutTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sinBuckling)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.helicalBuckling)) return chartData
    if (tndData.current.snapShotOutput.tripInTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.rotatingOffBottomTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.tripOutTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.sinBuckling.length === 0) return chartData
    if (tndData.current.snapShotOutput.helicalBuckling.length === 0) return chartData

    for (let i = 0; i < tndData.current.snapShotOutput.tripInTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.tripInTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.snapShotOutput.tripInTension.length),
        backgroundColor: interpChartColor('red', i, tndData.current.snapShotOutput.tripInTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.rotatingOffBottomTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.rotatingOffBottomTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOffBottomTension.length),
        backgroundColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOffBottomTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.tripOutTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.tripOutTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, tndData.current.snapShotOutput.tripOutTension.length),
        backgroundColor: interpChartColor('blue', i, tndData.current.snapShotOutput.tripOutTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sinBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sinBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        backgroundColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 255, 0)' : null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.helicalBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.helicalBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        backgroundColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 0, 0)' : null,
      })
    }

    return chartData
  }

  const getAppWobData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('apparentWob')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.apparentWob)) return chartData
    if (tndData.current.drillersOutput.apparentWob.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.apparentWob.length; i++) {
      let curSeries = tndData.current.drillersOutput.apparentWob[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.apparentWob.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.apparentWob.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    return chartData
  }

  const getWrapsData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('wraps')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.wraps)) return chartData
    if (tndData.current.drillersOutput.wraps.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.wraps.length; i++) {
      let curSeries = tndData.current.drillersOutput.wraps[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.wraps.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.wraps.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    return chartData
  }

  const getStretchData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('stretch')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.stretch)) return chartData
    if (tndData.current.drillersOutput.stretch.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.stretch.length; i++) {
      let curSeries = tndData.current.drillersOutput.stretch[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.stretch.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.stretch.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    return chartData
  }

  const getSideForceUnits = () => {
    if (!tndData.current) return ''
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return ''
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceRotating')) return ''
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceRotating)) return ''
    if (tndData.current.snapShotOutput.sideForceRotating.length === 0) return ''
    let unitsText = tndData.current.snapShotOutput.sideForceRotating[0].title
    unitsText = unitsText.replace('SF Rot ', '')
    unitsText = unitsText.replace('(', '')
    unitsText = unitsText.replace(')', '')
    return unitsText
  }

  const getWOBtext = () => {
    if (!tndData.current) return ''
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return ''
    if (!tndData.current.snapShotOutput.hasOwnProperty('rotatingOnBottomTension')) return ''
    if (!Array.isArray(tndData.current.snapShotOutput.rotatingOnBottomTension)) return ''
    if (tndData.current.snapShotOutput.rotatingOnBottomTension.length === 0) return ''
    let unitsText = tndData.current.snapShotOutput.rotatingOnBottomTension[0].title
    unitsText = unitsText.replace('Rot On Bottom @ WOB ', '')
    return unitsText
  }

  const getSideForceData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceTripIn')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceRotating')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceTripOut')) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceTripIn)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceRotating)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceTripOut)) return chartData
    if (tndData.current.snapShotOutput.sideForceTripIn.length === 0) return chartData
    if (tndData.current.snapShotOutput.sideForceRotating.length === 0) return chartData
    if (tndData.current.snapShotOutput.sideForceTripOut.length === 0) return chartData

    for (let i = 0; i < tndData.current.snapShotOutput.sideForceTripIn.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sideForceTripIn[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.snapShotOutput.sideForceTripIn.length),
        backgroundColor: interpChartColor('red', i, tndData.current.snapShotOutput.sideForceTripIn.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sideForceRotating.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sideForceRotating[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.snapShotOutput.sideForceRotating.length),
        backgroundColor: interpChartColor('green', i, tndData.current.snapShotOutput.sideForceRotating.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sideForceTripOut.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sideForceTripOut[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sideForceTripOut.length),
        backgroundColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sideForceTripOut.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getTndWarnings = () => {
    if (!tndData.current) return []
    if (!tndData.current.hasOwnProperty('warnings')) return []
    if (!Array.isArray(tndData.current.warnings)) return []
    if (tndData.current.warnings.length === 0) return []

    return tndData.current.warnings
  }

  const getTndErrors = () => {
    if (!tndData.current) return []
    if (!tndData.current.hasOwnProperty('errors')) return []
    if (!Array.isArray(tndData.current.errors)) return []
    if (tndData.current.errors.length === 0) return []

    return tndData.current.errors
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const generatePdfDocument = async (
    drillersHookloadsImage,
    calculatedFrictionFactorsImage,
    drillersTorquesImage,
    tensionSnapshotImage,
    tensionOffBtmSnapshotImage,
    sideForceImage,
    appWobImage,
    wrapsImage,
    stretchImage,
    imageHeight,
  ) => {
    if (!tndData.current) return
    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: 'Torque And Drag Report',
        showLogo: true,
        logo: getLogo('Primary'),
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['50%', '50%'],
        data: [
          [
            { text: 'Well:', isHeader: true },
            { text: currentWellRef.current, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [
            { text: 'Client:', isHeader: true },
            { text: tndData.current.jobDetails?.operator, textAlign: 'left' },
            { text: 'Field:', isHeader: true },
            { text: tndData.current.jobDetails?.field, textAlign: 'left' },
            { text: 'Well:', isHeader: true },
            { text: tndData.current.jobDetails?.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
            { text: 'Prepared By:', isHeader: true },
            { text: getUserNameFromEmail(user?.name), textAlign: 'left' },
            { text: 'Date:', isHeader: true },
            { text: dateTimeFormatter(new Date(Date.now()).toISOString()), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [{ text: 'Calculation Parameters', columnSpan: 6 }],
          [
            { text: `Calculation Depth (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
            { text: numberWithCommasDecimals(tndData.current.params?.calcDepth, 2), textAlign: 'left' },
            { text: `Mud Weight (${getUnitsText(UNITS_FOR.UnitWeight)}):`, isHeader: true },
            { text: numberWithCommasDecimals(tndData.current.params?.mudWeight, 2), textAlign: 'left' },
            { text: `Torque on Bit (${getUnitsText(UNITS_FOR.Torque)}):`, isHeader: true },
            { text: numberWithCommasDecimals(tndData.current.params?.torqueOnBit, 2), textAlign: 'left' },
          ],
          [
            { text: `Weight on Bit (${getUnitsText(UNITS_FOR.Weight)}):`, isHeader: true },
            { text: numberWithCommasDecimals(tndData.current.params?.wob, 2), textAlign: 'left' },
            { text: 'PV', isHeader: true },
            { text: numberWithCommasDecimals(tndData.current.fluids?.pv, 2), textAlign: 'left' },
            { text: 'YP', isHeader: true },
            { text: numberWithCommasDecimals(tndData.current.fluids?.yp, 2), textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['25%', '25%', '25%', '25%'],
        wrap: false,
        data: addWellboreGeometry(tndData.current.wellGeometry),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['4%', '4%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '12%'],
        wrap: false,
        data: addDrillStrings(tndData.current.drillStrings, tndData.current.params?.calcDepth),
      },
      {
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['12%', '11%', '11%', '11%', '11%', '11%', '11%', '11%', '11%'],
        data: addTndSummary(tndData.current.tndSummary),
      },
    ]

    let images = getImagesForChart(
      drillersHookloadsImage,
      calculatedFrictionFactorsImage,
      drillersTorquesImage,
      tensionSnapshotImage,
      tensionOffBtmSnapshotImage,
      sideForceImage,
      appWobImage,
      wrapsImage,
      stretchImage,
      imageHeight,
    )

    for (let i = 0; i < images.length; i++) {
      docData.push(images[i])
    }

    return docData
  }

  const getImagesForChart = (
    drillersHookloadsImage,
    calculatedFrictionFactorsImage,
    drillersTorquesImage,
    tensionSnapshotImage,
    tensionOffBtmSnapshotImage,
    sideForceImage,
    appWobImage,
    wrapsImage,
    stretchImage,
    imageHeight,
  ) => {
    let images = []

    let numOfCharts = 0
    const reportDetails = getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')
    if (!Array.isArray(reportDetails)) return images
    const reportDetail = reportDetails.find((detail) => detail.reportName === 'Torque & Drag')
    if (!reportDetail) return images
    if (!reportDetail.hasOwnProperty('reportDetails')) return images
    if (!Array.isArray(reportDetail.reportDetails)) return images
    for (let i = 0; i < reportDetail.reportDetails.length; i++) {
      if (!reportDetail.reportDetails[i].hasOwnProperty('charts')) continue
      if (!reportDetail.reportDetails[i].hasOwnProperty('reportType')) continue
      if (reportDetail.reportDetails[i].charts) {
        numOfCharts++
        if (reportDetail.reportDetails[i].reportType === 'Drillers Hookloads') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: drillersHookloadsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Calculated Friction Factors') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: calculatedFrictionFactorsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Drillers Torques') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: drillersTorquesImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'On Btm Tension Snapshot') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: tensionSnapshotImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Off Btm Tension Snapshot') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: tensionOffBtmSnapshotImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Side Force') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: sideForceImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Apparent WOB') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: appWobImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Pipe Twist') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: wrapsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Pipe Stretch') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: stretchImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
      }
    }

    return images
  }

  const addWellboreGeometry = (wellGeometry) => {
    if (!wellGeometry) return []
    if (!Array.isArray(wellGeometry)) return []

    let data = []
    data.push(
      [{ text: 'Wellbore Geometry', columnSpan: 4 }],
      [
        { text: 'Component Type', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < wellGeometry.length; i++) {
      data.push([
        { text: unescapeHtml(wellGeometry[i].compType) },
        { text: numberWithCommasDecimals(wellGeometry[i].md, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].od, 3) },
        { text: numberWithCommasDecimals(wellGeometry[i].id, 3) },
      ])
    }

    return data
  }

  const addDrillStrings = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Data', columnSpan: 13 }],

      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: 'Type', isHeader: true },
        { text: 'Material', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Unit Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCompWeight = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      totalCompWeight = totalCompWeight + drillStrings[i].casingWeight * drillStrings[i].length
      cumLength += drillStrings[i].length

      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length
      let image = bhaImages.getPrintImage(drillStrings[i], false)

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].type.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].material?.materialName.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeOd, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeId, 3) },
        { text: drillStrings[i].tjod ? numberWithCommasDecimals(drillStrings[i].tjod, 3) : '-' },
        { text: drillStrings[i].tjid ? numberWithCommasDecimals(drillStrings[i].tjid, 3) : '-' },
        { text: numberWithCommasDecimals(drillStrings[i].casingWeight, 2) },
        { text: numberWithCommasDecimals(drillStrings[i].length, 2) },
        { text: numberWithCommasDecimals(cumLength, 2) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    return data
  }

  const addTndSummary = (tndSummary) => {
    if (!tndSummary) return []
    if (!Array.isArray(tndSummary)) return []

    let data = []
    data.push(
      [{ text: 'Torque And Drag Summary', columnSpan: 9, backgroundColor: '#FFFFFF' }],
      [
        { text: 'Operation', isHeader: true },
        { text: `Surface Wt (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Surface Tq (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Stretch (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: 'Wraps', isHeader: true },
        { text: 'Buckling', isHeader: true },
        { text: 'OH FF', isHeader: true },
        { text: 'CH FF', isHeader: true },
        { text: `RPM`, isHeader: true },
      ],
    )

    for (let i = 0; i < tndSummary.length; i++) {
      data.push([
        { text: unescapeHtml(tndSummary[i].operation.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(tndSummary[i].surfaceWeight, 2) },
        { text: numberWithCommasDecimals(tndSummary[i].surfaceTorque, 2) },
        { text: numberWithCommasDecimals(tndSummary[i].stretch, 2) },
        { text: numberWithCommasDecimals(tndSummary[i].wraps, 2) },
        { text: unescapeHtml(tndSummary[i].buckling.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(tndSummary[i].ohFf, 2) },
        { text: numberWithCommasDecimals(tndSummary[i].chFf, 2) },
        { text: numberWithCommasDecimals(tndSummary[i].rpm, 2) },
      ])
    }

    return data
  }

  const getTndPdfData = (chartElement, wellElement) => {
    return new Promise(async (resolve) => {
      if (typeof currentWellRef.current !== 'string') return
      if (currentWellRef.current === '') return
      if (!selectedEngCase) return
      if (!selectedEngCase.hasOwnProperty('recordId')) return

      let title = `Torque and Drag Report`

      let drillersHookloadsElement = chartElement.getChartType('drillersHookloads')
      const drillersHookloadsCanvas = await html2canvas(drillersHookloadsElement.current)
      const drillersHookloadsImage = drillersHookloadsCanvas.toDataURL('image/png', 1.0)

      let calculatedFrictionFactorsElement = chartElement.getChartType('calculatedFrictionFactors')
      const calculatedFrictionFactorsCanvas = await html2canvas(calculatedFrictionFactorsElement.current)
      const calculatedFrictionFactorsImage = calculatedFrictionFactorsCanvas.toDataURL('image/png', 1.0)

      let drillersTorquesElement = chartElement.getChartType('drillersTorques')
      const drillersTorquesCanvas = await html2canvas(drillersTorquesElement.current)
      const drillersTorquesImage = drillersTorquesCanvas.toDataURL('image/png', 1.0)

      let tensionSnapshotElement = chartElement.getChartType('tensionSnapshot')
      const tensionSnapshotCanvas = await html2canvas(tensionSnapshotElement.current)
      const tensionSnapshotImage = tensionSnapshotCanvas.toDataURL('image/png', 1.0)

      let tensionOffBtmSnapshotElement = chartElement.getChartType('tensionOffBtmSnapshot')
      const tensionOffBtmSnapshotCanvas = await html2canvas(tensionOffBtmSnapshotElement.current)
      const tensionOffBtmSnapshotImage = tensionOffBtmSnapshotCanvas.toDataURL('image/png', 1.0)

      let sideForceElement = chartElement.getChartType('sideForce')
      const sideForceCanvas = await html2canvas(sideForceElement.current)
      const sideForceImage = sideForceCanvas.toDataURL('image/png', 1.0)

      let appWobElement = chartElement.getChartType('appWob')
      const appWobCanvas = await html2canvas(appWobElement.current)
      const appWobImage = appWobCanvas.toDataURL('image/png', 1.0)

      let wrapsElement = chartElement.getChartType('wraps')
      const wrapsCanvas = await html2canvas(wrapsElement.current)
      const wrapsImage = wrapsCanvas.toDataURL('image/png', 1.0)

      let stretchElement = chartElement.getChartType('stretch')
      const stretchCanvas = await html2canvas(stretchElement.current)
      const stretchImage = stretchCanvas.toDataURL('image/png', 1.0)

      const pdfDoc = await generatePdfDocument(
        drillersHookloadsImage,
        calculatedFrictionFactorsImage,
        drillersTorquesImage,
        tensionSnapshotImage,
        tensionOffBtmSnapshotImage,
        sideForceImage,
        appWobImage,
        wrapsImage,
        stretchImage,
        300,
      )

      resolve({
        fileName: `${title} - ${replaceEscapeSymbols(removeSpecialSymbols(currentWellRef.current))}`,
        data: (
          <PdfDocument
            data={pdfDoc}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  function interpExtrapFrictionFactor(data, measuredValue) {
    if (!Array.isArray(data) || data.length === 0) return -999.25
    data.sort((a, b) => a.value - b.value)

    let point1 = null
    let point2 = null

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].value <= measuredValue && data[i + 1].value >= measuredValue) {
        point1 = data[i]
        point2 = data[i + 1]
        break
      }
    }

    // If measured value is outside the range of the data, extrapolate
    if (!point1 || !point2) {
      if (measuredValue <= data[0].value) {
        point1 = data[0]
        point2 = data[1]
      } else {
        point1 = data[data.length - 2]
        point2 = data[data.length - 1]
      }
    }

    const slope = (point2.ff.oh - point1.ff.oh) / (point2.value - point1.value)
    const interpolatedFf = point1.ff.oh + slope * (measuredValue - point1.value)
    if (interpolatedFf < 0) return -999.25
    return interpolatedFf
  }

  function interpValue(p1, p2, depth) {
    if (!p1 || !p2) return -999.25

    const slope = (p2.data - p1.data) / (p2.depth - p1.depth)
    const interp = p1.data + slope * (depth - p1.depth)
    return interp
  }

  const extractFrictionFactorsFromTitle = (title) => {
    let output = { ch: -1, oh: -1 }
    if (!title) return output
    if (typeof title !== 'string') return output
    if (title === '') return output

    let splitTitle = title.split('@')

    if (splitTitle.length !== 2) return output
    let splitFrictionFactor = splitTitle[1].split('OH')
    if (splitFrictionFactor.length !== 2) return output

    let chFf = parseFloat(splitFrictionFactor[0].replace('CH', '').trim())
    let ohFf = parseFloat(splitFrictionFactor[1].trim())

    output.ch = chFf
    output.oh = ohFf
    return output
  }

  const getInterpolatedHookloadData = (hookloadData, depth) => {
    if (!Array.isArray(hookloadData) || hookloadData.length === 0) return null
    if (!Array.isArray(hookloadData[0]?.data)) return null
    if (hookloadData[0].data.length <= 1) return null

    let frictionFactors = []
    for (let i = 0; i < hookloadData.length; i++) {
      let ff = extractFrictionFactorsFromTitle(hookloadData[i].title)
      if (ff.oh < 0) continue
      frictionFactors.push(ff)
    }

    if (frictionFactors.length !== hookloadData.length) return null

    let depthIndex1 = -1
    let depthIndex2 = -1

    for (let i = 1; i < hookloadData[0].data.length; i++) {
      if (depth > hookloadData[0].data[i].depth) continue

      depthIndex1 = i - 1
      depthIndex2 = i
      break
    }

    if (depthIndex1 < 0 || depthIndex2 < 0) return null
    return frictionFactors.map((ff, index) => {
      return {
        value: interpValue(hookloadData[index].data[depthIndex1], hookloadData[index].data[depthIndex2], depth),
        ff: ff,
      }
    })
  }

  function movingAverage(points, windowSize) {
    if (points.length < windowSize) {
      windowSize = points.length
    }

    const smoothedPoints = []
    for (let i = 0; i < points.length - windowSize + 1; i++) {
      let sumX = 0
      let sumY = 0

      for (let j = i; j < i + windowSize; j++) {
        sumX += points[j].x
        sumY += points[j].y
      }

      const smoothedPoint = {
        x: sumX / windowSize,
        y: sumY / windowSize,
      }

      smoothedPoints.push(smoothedPoint)
    }

    return smoothedPoints
  }

  const getCalculatedFrictionFactorData = (slideSheetData, witsData) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripInWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripOutWeight')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripInWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripOutWeight)) return chartData
    if (tndData.current.drillersOutput.tripInWeight.length === 0) return chartData
    if (tndData.current.drillersOutput.tripOutWeight.length === 0) return chartData

    let hasSlideSheetData = Array.isArray(slideSheetData) && slideSheetData.length > 0
    let hasWitsData = Array.isArray(witsData) && witsData.length > 0
    if (!hasSlideSheetData && !hasWitsData) return chartData

    chartData.datasets.push({
      label: 'PU FF (Slide Sheet)',
      borderColor: interpChartColor('blue', 0, 2),
      backgroundColor: interpChartColor('blue', 0, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'SO FF (Slide Sheet)',
      borderColor: interpChartColor('red', 0, 2),
      backgroundColor: interpChartColor('red', 0, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'PU FF (WITS)',
      borderColor: interpChartColor('blue', 1, 2),
      backgroundColor: interpChartColor('blue', 1, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'SO FF (WITS)',
      borderColor: interpChartColor('red', 1, 2),
      backgroundColor: interpChartColor('red', 1, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'Trip Out FF (WITS)',
      borderColor: interpChartColor('yellow', 1, 2),
      backgroundColor: interpChartColor('yellow', 1, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'Trip In FF (WITS)',
      borderColor: interpChartColor('purple', 1, 2),
      backgroundColor: interpChartColor('purple', 1, 2),
      data: [],
    })

    if (hasSlideSheetData) {
      let puIndex = slideSheetData.findIndex((item) => item.label === 'P/U Weight')
      let soIndex = slideSheetData.findIndex((item) => item.label === 'S/O Weight')

      if (puIndex >= 0 && soIndex >= 0) {
        for (let i = 0; i < slideSheetData[puIndex].data.length; i++) {
          let depth = slideSheetData[puIndex].data[i].y
          let slideSheetPu = slideSheetData[puIndex].data[i].x
          let slideSheetSo = slideSheetData[soIndex].data[i].x

          let calcPuData = getInterpolatedHookloadData(tndData.current.drillersOutput.tripOutWeight, depth)
          let calcSoData = getInterpolatedHookloadData(tndData.current.drillersOutput.tripInWeight, depth)

          let puFf = interpExtrapFrictionFactor(calcPuData, slideSheetPu)
          let soFf = interpExtrapFrictionFactor(calcSoData, slideSheetSo)

          if (puFf > 0) chartData.datasets[0].data.push({ x: puFf, y: depth })
          if (soFf > 0) chartData.datasets[1].data.push({ x: soFf, y: depth })
        }

        let windowSize = Math.floor(chartData.datasets[0].data.length / 5)
        if (windowSize <= 0) windowSize = 1

        chartData.datasets[0].data = movingAverage(chartData.datasets[0].data, windowSize)
        chartData.datasets[1].data = movingAverage(chartData.datasets[1].data, windowSize)
      }
    }

    if (hasWitsData) {
      let puIndex = witsData.findIndex((item) => item.label === 'P/U Weight (WITS)')
      let soIndex = witsData.findIndex((item) => item.label === 'S/O Weight (WITS)')
      let tripOutIndex = witsData.findIndex((item) => item.label === 'Trip Out Weight (WITS)')
      let tripInIndex = witsData.findIndex((item) => item.label === 'Trip In Weight (WITS)')

      let indexArray = [
        { index: puIndex, tag: 'tripOutWeight' },
        { index: soIndex, tag: 'tripInWeight' },
        { index: tripOutIndex, tag: 'tripOutWeight' },
        { index: tripInIndex, tag: 'tripInWeight' },
      ]

      let windowSize = Math.floor(chartData.datasets[0].data.length / 5)
      if (windowSize <= 0) windowSize = 1

      for (let i = 0; i < indexArray.length; i++) {
        if (indexArray[i] < 0) continue
        let index = indexArray[i].index

        for (let j = 0; j < witsData[index].data.length; j++) {
          let depth = witsData[index].data[j].y
          let val = witsData[index].data[j].x

          let calcData = getInterpolatedHookloadData(tndData.current.drillersOutput[indexArray[i].tag], depth)
          let ff = interpExtrapFrictionFactor(calcData, val)
          if (ff > 0) chartData.datasets[i + 2].data.push({ x: interpExtrapFrictionFactor(calcData, val), y: depth })
        }

        chartData.datasets[i + 2].data = movingAverage(chartData.datasets[i + 2].data, windowSize)
      }
    }

    return chartData
  }

  return {
    loading,
    fetchTnd,
    getDrillersHookloadsData,
    getDrillersTorquesData,
    getGeometryAnnotations,
    getTensionSnapshotData,
    getTndWarnings,
    getTndErrors,
    getTndPdfData,
    getTensionOffBtmSnapshotData,
    getSideForceData,
    getAppWobData,
    getWrapsData,
    getStretchData,
    getSideForceUnits,
    getWOBtext,
    getCalculatedFrictionFactorData,
  }
}

export default useTorqueAndDrag
