import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import {
  wellListAtom,
  wellListLoadingAtom,
  onlyShowFollowingWellsAtom,
  userUserRoleAtom,
  currentWellSelector,
  currentWellDetailsSelector,
} from 'atoms'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { escapeHtml, unescapeHtml } from 'utils/htmlSymbolHandling'
import { appColors } from 'utils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import GlobalPrefs from 'components/NavBar/GlobalPrefs'
import SearchBar from 'components/common/SearchBar'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import useUpdateWellInfo from 'components/common/hooks/useUpdateWellInfo'
import { checkPermission } from 'components/userPermissions'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import WellInfo from './WellInfo'
import { useNavigate } from 'react-router-dom'
import {
  sortColDefs,
  relativeTimeMins,
  dateComparator,
  isDateLessThan,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import { debounce} from 'lodash'
import { checkFeature } from '../userPermissions'
import OasisJobInfoModal from './OasisJobInfoModal'
import SapJobInfoModal from './SapJobInfoModal'
import SureShopJobInfoModal from './SureShopJobInfoModal'
import OracleJobInfoModal from './OracleJobInfoModal'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useUserDatabaseAccess from 'components/common/hooks/useUserDatabaseAccess'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

const WellListGrid = ({ setFilteredData }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const selectedWell = useRef(null)
  const isCreatingOracleBid = useRef(false)
  const isPushingCosts = useRef(false)
  const isAddingConfig = useRef(false)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const [wells, setWells] = useRecoilState(wellListAtom)
  const isLoading = useRecoilValue(wellListLoadingAtom)
  const [showOasisModal, setShowOasisModal] = useState(false)
  const [showSapModal, setShowSapModal] = useState(false)
  const [showOracleModal, setShowOracleModal] = useState(false)
  const [showSureShopModal, setShowSureShopModal] = useState(false)
  const [groupRows, setGroupRows] = useState(getItemFromLS('wellListGridGrouping', 'grouping'))
  const [searchText, setSearchText] = useState('')
  const { isObjectOfInterest, hasObjectsOfInterest } = useObjectsOfInterest()
  const { getDropDownList, handleUpdate: updateWellInfo, hasCountrys } = useUpdateWellInfo()
  const onlyShowFollowing = useRecoilValue(onlyShowFollowingWellsAtom)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [dataToUpdate, setDataToUpdate] = useState(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const setCurrentWell = useSetRecoilState(currentWellSelector)
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsSelector)
  const navigate = useNavigate()
  const initialFilters = useRef(getItemFromLS('wellListGridFilters', 'filters') || null)
  const { hasMultiDbAccess, setAccessDatabase } = useUserDatabaseAccess(false)
  const { getDatabaseOrg } = useInnovaAuth()

  const createOracleBid = useInnovaAxios({
    url: '/oracle/createBid',
  })

  const addDataConfig = useInnovaAxios({
    url: '/dataAcq/addConfigs',
  })

  const pushCostsToSap = useInnovaAxios({
    url: '/sap/pushCostCodes',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    saveItemToLS('wellListGridGrouping', 'grouping', groupRows)
  }, [groupRows])

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
    if (gridApi.current) gridApi.current.onFilterChanged()
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [onlyShowFollowing, wells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleUpdate()
  }, [dataToUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateOracleBid = async (data) => {
    if (isCreatingOracleBid.current) return
    isCreatingOracleBid.current = true
    let res = await createOracleBid({ wellName: data.actualWell })
    isCreatingOracleBid.current = false

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: res?.error?.response?.data?.error,
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: 'Bid synced with Oracle',
    })
  }

  const handlePushCostsToSap = async (data) => {
    if (isPushingCosts.current) return
    isPushingCosts.current = true
    let res = await pushCostsToSap({ wellNames: JSON.stringify([data.actualWell]) })
    isPushingCosts.current = false

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: res?.error?.response?.data?.error,
      })

      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: 'Costs pushed to SAP',
    })
  }

  const handleUpdate = async () => {
    if (!dataToUpdate) return

    if (!dataToUpdate.hasOwnProperty('rig')) {
      let wellIndex = wells.findIndex((well) => well.actualWell === dataToUpdate.actualWell)
      if (wellIndex < 0) return
      dataToUpdate.rig = wells[wellIndex].rig
    }

    if (dataToUpdate.hasOwnProperty('description')) {
      dataToUpdate.description = escapeHtml(dataToUpdate?.description)
    }

    let res = await updateWellInfo(dataToUpdate)

    if (res.isError) {
      setStatus({
        show: true,
        severity: 'error',
        message: res.message,
      })
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({ update: [dataToUpdate] })
      let wellsUpdated = cloneDeep(wells)
      let wellIndex = wellsUpdated.findIndex((well) => well.actualWell === dataToUpdate.actualWell)
      if (wellIndex < 0) return

      wellsUpdated[wellIndex].rig = dataToUpdate.rig
      wellsUpdated[wellIndex].wellStatus = dataToUpdate.wellStatus
      wellsUpdated[wellIndex].state = dataToUpdate.state
      wellsUpdated[wellIndex].county = dataToUpdate.county
      wellsUpdated[wellIndex].ddCoordinator = dataToUpdate.ddCoordinator
      wellsUpdated[wellIndex].mwdCoordinator = dataToUpdate.mwdCoordinator
      wellsUpdated[wellIndex].jobNum = dataToUpdate.jobNum
      wellsUpdated[wellIndex].district = dataToUpdate.district
      wellsUpdated[wellIndex].description = dataToUpdate.description
      setWells(wellsUpdated)
    }
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const canEdit = useMemo(() => {
    if (userRole.roleAttributes?.permissions?.admin || userRole.innovaAdmin) return true
    if (checkPermission('canEdit', userRole.roleAttributes?.permissions)) return true
    return false
  }, [userRole])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('wellListGrid', 'colLayout', colLayout)
    }
  }

  const saveFilterState = () => {
    if (gridApi.current) {
      const filters = gridApi.current.getFilterModel()
      if (filters) saveItemToLS('wellListGridFilters', 'filters', filters)
    }
  }

  const handleAddDataConfig = async (data) => {
    if (!data) return
    if (typeof data.actualWell !== 'string' || data.actualWell === '') return
    if (isAddingConfig.current) return
    isAddingConfig.current = true

    let res = await addDataConfig({ actualWell: data.actualWell })
    isAddingConfig.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Data config created`,
    })
  }

  const getContextMenuItems = (params) => {
    let contextMenuItems = [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellListGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'separator',
      {
        name: 'Row Grouping',
        subMenu: [
          {
            name: 'Group by operator',
            disabled: groupRows === 'operator',
            action: () => {
              setGroupRows('operator')
            },
            icon: '<span class="iconify" data-icon="ic:sharp-oil-barrel" data-width="20" style="color:#4BB2F9"></span>',
            cssClasses: ['leftAlign'],
          },
          {
            name: 'Group by rig',
            disabled: groupRows === 'rig',
            action: () => {
              setGroupRows('rig')
            },
            icon: '<span class="iconify" data-icon="game-icons:oil-rig" data-width="20" style="color:#4BB2F9"></span>',
            cssClasses: ['leftAlign'],
          },
          {
            name: 'Ungroup rows',
            disabled: groupRows === null,
            action: () => {
              setGroupRows(null)
            },
            icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
            cssClasses: ['leftAlign'],
          },
        ],
      },
      'separator',
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'separator',
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WellsData.xlsx',
            sheetName: 'Wells',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'separator',
      {
        name: 'Create Data Config',
        disabled: false,
        action: () => {
          handleAddDataConfig(params.node.data)
        },
        icon: '<span class="iconify" data-icon="material-symbols:settings" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]

    let oasisEnabled = checkFeature(12, userRole?.roleAttributes?.featureId)
    let sapEnabled = checkFeature(14, userRole?.roleAttributes?.featureId)
    let sureShopEnabled = checkFeature(47, userRole?.roleAttributes?.featureId)
    let oracleEnabled = checkFeature(49, userRole?.roleAttributes?.featureId)

    if (oasisEnabled || sapEnabled || sureShopEnabled || oracleEnabled) {
      let jobInfoSubMenu = {
        name: 'ERP Systems',
        subMenu: [],
      }

      if (oasisEnabled) {
        jobInfoSubMenu.subMenu.push({
          name: 'Get Oasis job info',
          disabled: false,
          action: () => {
            selectedWell.current = params.node.data
            setShowOasisModal(true)
          },
          icon: '<span class="iconify" data-icon="fa6-solid:o" data-width="20" style="color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        })
      }

      if (sapEnabled) {
        jobInfoSubMenu.subMenu.push({
          name: 'Get SAP job info',
          disabled: false,
          action: () => {
            selectedWell.current = params.node.data
            setShowSapModal(true)
          },
          icon: '<span class="iconify" data-icon="logos:sap" data-width="20"></span>',
          cssClasses: ['leftAlign'],
        })

        jobInfoSubMenu.subMenu.push({
          name: 'Push Costs to SAP',
          disabled: false,
          action: () => {
            handlePushCostsToSap(params.node.data)
          },
          icon: '<span class="iconify" data-icon="solar:dollar-bold" data-width="20"></span>',
          cssClasses: ['leftAlign'],
        })
      }

      if (sureShopEnabled) {
        jobInfoSubMenu.subMenu.push({
          name: 'Get SureShop job info',
          disabled: false,
          action: () => {
            selectedWell.current = params.node.data
            setShowSureShopModal(true)
          },
          icon: '<span class="iconify" data-icon="noto:wrench" data-width="20"></span>',
          cssClasses: ['leftAlign'],
        })
      }

      if (oracleEnabled) {
        jobInfoSubMenu.subMenu.push({
          name: 'Get Oracle job info',
          disabled: false,
          action: () => {
            selectedWell.current = params.node.data
            setShowOracleModal(true)
          },
          icon: '<span class="iconify" data-icon="logos:oracle" data-width="20"></span>',
          cssClasses: ['leftAlign'],
        })

        jobInfoSubMenu.subMenu.push({
          name: 'Sync bid with Oracle',
          disabled: false,
          action: () => {
            handleCreateOracleBid(params.node.data)
          },
          icon: '<span class="iconify" data-icon="solar:dollar-bold" data-width="20"></span>',
          cssClasses: ['leftAlign'],
        })
      }

      contextMenuItems.splice(2, 0, 'separator')
      contextMenuItems.splice(3, 0, jobInfoSubMenu)
    }

    return contextMenuItems
  }

  const isExternalFilterPresent = useCallback(() => {
    return onlyShowFollowing === true
  }, [onlyShowFollowing])

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        return isObjectOfInterest(node.data.rig, node.data.operator, node.data.district)
      }
      return true
    },
    [isObjectOfInterest],
  )

  const dateFilterComparator = useCallback(dateComparator, [])
  const relativeTimeFormatter = useCallback(relativeTimeMins, [])

  const getTimeDurationColor = (timeSinceMins) => {
    if (timeSinceMins === null || timeSinceMins === undefined) return 'tomato'
    if (typeof timeSinceMins === 'string' && timeSinceMins === '') return 'tomato'
    if (typeof timeSinceMins === 'string') timeSinceMins = parseFloat(timeSinceMins)

    if (timeSinceMins > 240) return 'tomato'
    if (timeSinceMins > 60) return 'orange'
    return 'green'
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = useMemo(
    () => (first, second) => {
      let largestAmount = 0
      if (wells?.length <= 0) return 1
      wells.forEach((item) => {
        if (item[first] > largestAmount) {
          largestAmount = item[first]
        }
        if (item[second] > largestAmount) {
          largestAmount = item[second]
        }
      })

      return largestAmount
    },
    [wells],
  )

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const threeValColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const dateTimeFormatter = useMemo(
    () => (value) => {
      if (!value) return ''
      if (typeof value !== 'string') return ''
      if (value === '') return ''
      if (isDateLessThan(value, '1990-01-01')) return ''
      value = value.replace(/Z/g, '')

      return new Date(Date.parse(value)).toLocaleDateString('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    [],
  )

  const sortWells = (wells) => {
    if (!Array.isArray(wells)) return []
    if (!hasObjectsOfInterest()) return wells
    let following = []
    let notFollowing = []

    for (let i = 0; i < wells.length; i++) {
      if (isObjectOfInterest(wells[i].rig, wells[i].operator, wells[i].district)) {
        following.push(wells[i])
        continue
      }

      notFollowing.push(wells[i])
    }

    return [...following, ...notFollowing]
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'info',
        pinned: 'left',
        lockPosition: 'left',
        sortable: false,
        filter: null,
        cellRenderer: 'agGroupCellRenderer',
        width: 65,
        cellStyle: {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      {
        headerName: 'Fav',
        width: 100,
        colId: 'favouite',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        valueGetter: (params) => isObjectOfInterest(params.data?.rig, params.data?.operator, params.data?.district),
        cellRenderer: (params) => {
          if (!isObjectOfInterest(params.data?.rig, params.data?.operator, params.data?.district)) return null
          return <Iconify icon='emojione:star' width='25' height='25' />
        },
      },
      {
        headerName: '',
        width: 60,
        colId: 'avatar',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={(event) => {
                event.stopPropagation()
                setCurrentWellDetails({
                  actualWell: params.data?.actualWell,
                  wellStatus: params.data?.wellStatus,
                  operator: params.data?.operator,
                  rig: params.data?.rig,
                  jobNum: params.data?.jobNum,
                  lat: params.data?.latitude,
                  lng: params.data?.longitude,
                  org: params?.data?.organization,
                })
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        headerName: 'Well',
        field: 'actualWell',
        colId: 'well',
        minWidth: 300,
        maxWidth: 600,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={(event) => {
                let dbChanged = false
                if (hasMultiDbAccess() && getDatabaseOrg() !== params?.data?.organization){
                  dbChanged = true
                  setAccessDatabase(params?.data?.organization)
                }
                event.stopPropagation()
                setCurrentWell({
                  wellName: params?.data?.actualWell,
                  organization: params?.data?.organization,
                })
                setCurrentWellDetails({
                  actualWell: params.data?.actualWell,
                  wellStatus: params.data?.wellStatus,
                  operator: params.data?.operator,
                  rig: params.data?.rig,
                  jobNum: params.data?.jobNum,
                  lat: params.data?.latitude,
                  lng: params.data?.longitude,
                })
                navigate('/well-pages/well-page')
                if (dbChanged) window.location.reload()
              }}>
              {unescapeHtml(params.data?.actualWell)}
            </Box>
          )
        },
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        rowGroup: groupRows === 'operator',
        hide: groupRows === 'operator',
        minWidth: 200,
        maxWidth: 400,
        valueGetter: (params) => {
          return unescapeHtml(params.data?.operator)
        },
      },
      {
        headerName: 'Organization',
        field: 'organization',
        colId: 'organization',
        minWidth: 200,
        maxWidth: 400,
        valueGetter: (params) => {
          return unescapeHtml(params.data?.organization)
        },
      },
      {
        headerName: 'Job #',
        field: 'jobNum',
        colId: 'jobNum',
        cellStyle: centerAlignCell,
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.jobNum = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        valueFormatter: (params) => {
          return unescapeHtml(params.data?.jobNum)
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        rowGroup: groupRows === 'rig',
        hide: groupRows === 'rig',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.rig = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return unescapeHtml(params.data?.rig)
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('rigNames').sort() }
        },
      },
      {
        headerName: getDropDownList('countys', 'Texas')?.length > 0 ? 'State' : 'Country',
        field: 'state',
        colId: 'state',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.state = params.newValue
          if (params.newValue !== params.oldValue) {
            data.county = ''
          }
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        cellStyle: centerAlignCell,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('states').sort() }
        },
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        hide: hasCountrys,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.county = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        editable: canEdit,
        cellStyle: centerAlignCell,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('countys', params.data?.state).sort() }
        },
      },
      {
        headerName: 'Status',
        field: 'wellStatus',
        colId: 'status',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.wellStatus = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('wellStatus').sort() }
        },
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: GetActivityColor(params.data?.wellStatus),
          }
        },
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        colId: 'startDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.startDate)
        },
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        colId: 'endDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.endDate)
        },
      },
      {
        headerName: `Last Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'currentDepth',
        colId: 'currentDepth',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.currentDepth ? numberWithCommasDecimals(params.data?.currentDepth, 2) : ''
        },
      },
      {
        headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'totalDrilled',
        colId: 'totalDrilled',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.totalDrilled ? numberWithCommasDecimals(params.data?.totalDrilled, 2) : ''
        },
      },
      {
        headerName: 'Drilling Hrs',
        field: 'totalDrillingHrs',
        colId: 'totalDrillingHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.totalDrillingHrs ? numberWithCommasDecimals(params.data?.totalDrillingHrs, 2) : ''
        },
      },
      {
        headerName: 'DEX',
        field: 'icdsMinsSinceUpdate',
        colId: 'icdsUpdate',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getTimeDurationColor(params.data?.icdsMinsSinceUpdate),
          }
        },
        valueGetter: (params) => {
          if (!params.data) return 99999999
          if (!params.data?.hasOwnProperty('icdsMinsSinceUpdate')) return 99999999
          if (params.data?.icdsMinsSinceUpdate === null || params.data?.icdsMinsSinceUpdate === undefined) {
            return 99999999
          }
          if (typeof params.data?.icdsMinsSinceUpdate === 'string' && params.data?.icdsMinsSinceUpdate === '') {
            return 99999999
          }

          return parseFloat(params.data?.icdsMinsSinceUpdate)
        },
        valueFormatter: (params) => relativeTimeFormatter(params.data?.icdsMinsSinceUpdate),
      },
      {
        headerName: 'WITS',
        field: 'witsMinsSinceUpdate',
        colId: 'witsUpdate',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getTimeDurationColor(params.data?.witsMinsSinceUpdate),
          }
        },
        valueGetter: (params) => {
          if (!params.data) return 99999999
          if (!params.data?.hasOwnProperty('witsMinsSinceUpdate')) return 99999999
          if (params.data?.witsMinsSinceUpdate === null || params.data?.witsMinsSinceUpdate === undefined) {
            return 99999999
          }
          if (typeof params.data?.witsMinsSinceUpdate === 'string' && params.data?.witsMinsSinceUpdate === '') {
            return 99999999
          }

          return parseFloat(params.data?.witsMinsSinceUpdate)
        },
        valueFormatter: (params) => relativeTimeFormatter(params.data?.witsMinsSinceUpdate),
      },
      {
        headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'footage',
        autoHeight: true,
        sortable: false,
        width: 140,
        filter: null,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideDrilled],
            ['Rotate', params.data?.rotateDrilled],
          ]
        },
      },
      {
        headerName: `Drilling Hrs`,
        colId: 'drlgHrs',
        autoHeight: true,
        sortable: false,
        width: 140,
        filter: null,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideHrs', 'rotateHrs')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideHrs],
            ['Rotate', params.data?.rotateHrs],
          ]
        },
      },
      {
        headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        colId: 'rop',
        autoHeight: true,
        sortable: false,
        width: 140,
        filter: null,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            paddingOuter: 0.5,
            type: 'bar',
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: threeValColumnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideRop', 'rotateRop')],
            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide ROP', params.data?.slideRop],
            ['Rotate ROP', params.data?.rotateRop],
            ['Avg ROP', params.data?.avgRop],
          ]
        },
      },
      {
        headerName: 'DD Coord',
        field: 'ddCoordinator',
        colId: 'ddCoordinator',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.ddCoordinator = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('ddCoordinators').sort() }
        },
      },
      {
        headerName: 'MWD Coord',
        field: 'mwdCoordinator',
        colId: 'mwdCoordinator',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.mwdCoordinator = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('mwdCoordinators').sort() }
        },
      },
      {
        headerName: 'District',
        field: 'district',
        colId: 'district',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.district = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('district').sort() }
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        colId: 'description',
        editable: canEdit,
        valueSetter: (params) => {
          let data = { ...params.data }
          data.description = params.newValue
          if (_isMounted.current) setDataToUpdate(data)
          return true
        },
        valueFormatter: (params) => unescapeHtml(params.data?.description),
      },
    ],
    [
      canEdit,
      hasCountrys,
      getUnitsText,
      isObjectOfInterest,
      groupRows,
      dateFilterComparator,
      dateTimeFormatter,
      getHighestValue,
      getDropDownList,
      navigate,
      setCurrentWell,
      setCurrentWellDetails,
      relativeTimeFormatter,
      hasMultiDbAccess,
      setAccessDatabase,
      getDatabaseOrg,
    ],
  )

  const gridOptions = {
    suppressRowClickSelection: true,
    detailRowHeight: 200,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
      popupParent: document.querySelector('body'),
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onSortChanged: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    const colLayout = getItemFromLS('wellListGrid', 'colLayout')
    if (colLayout) {
      gridApi.current.applyColumnState({ state: colLayout })
    }
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (!node.data) return
      filteredNodes.push(node.data)
    })

    if (_isMounted.current && setFilteredData) {
      setFilteredData(filteredNodes)
    }

    saveFilterState()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()

    if (initialFilters.current) {
      gridApi.current.setFilterModel(initialFilters.current)
    }
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const detailCellRenderer = useMemo(() => {
    return WellInfo
  }, [])

  const getRowHeight = (params) => {
    return !params.node.detail ? undefined : 500
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
        <GlobalPrefs />
      </Box>
      {showOasisModal ? (
        <OasisJobInfoModal
          wellData={selectedWell.current}
          onClose={(data) => {
            setShowOasisModal(false)
            setDataToUpdate(data)
          }}
        />
      ) : null}
      {showSapModal ? (
        <SapJobInfoModal
          wellData={selectedWell.current}
          onClose={(data) => {
            setShowSapModal(false)
            setDataToUpdate(data)
          }}
        />
      ) : null}
      {showSureShopModal ? (
        <SureShopJobInfoModal
          wellData={selectedWell.current}
          onClose={(data) => {
            setShowSureShopModal(false)
            setDataToUpdate(data)
          }}
        />
      ) : null}
      {showOracleModal ? (
        <OracleJobInfoModal
          wellData={selectedWell.current}
          onClose={(data) => {
            setShowOracleModal(false)
            setDataToUpdate(data)
          }}
        />
      ) : null}
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={sortWells(wells)}
          columnDefs={sortColDefs(columnDefs, 'wellListGrid')}
          defaultColDef={defaultColDef}
          loading={isLoading}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          groupRowsSticky={true}
          onGridReady={onGridReady}
          masterDetail={true}
          gridOptions={gridOptions}
          detailCellRenderer={detailCellRenderer}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          getRowHeight={getRowHeight}
          headerHeight={30}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default WellListGrid
