import { useState, useRef, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { AgGridReact } from 'ag-grid-react'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import useUnits from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { array2pipestr } from 'utils'
import * as XLSX from '@sheet/core'
import TowercomparisonChart from './TowerComparisonChart'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const TowerComparisonKpiGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const shiftRef = useRef('six to six')
  const [showCumulative, setShowCumulative] = useState(false)

  const getTowerComparisonKpiData = useInnovaAxios({
    url: '/kpi/towercomparison',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.towerComparisonKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('towerComparisonKpiGrid', 'colLayout', colLayout)
    }
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const response = await getTowerComparisonKpiData({
      wellList: array2pipestr(selectedWells),
      timePeriod: shiftRef.current,
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(response.data ? response.data : [])
    }
  }

  const onXlsxExport = () => {
    if (!data) return
    if (!Array.isArray(data)) return

    let fileName = 'towerComparisonKpik.xlsx'
    let wb = XLSX.utils.book_new()

    let rowIndex = 1
    let headers = []
    headers.push('Well Name')
    headers.push('Rig')
    headers.push('Job#')
    headers.push('State')
    headers.push('County')
    headers.push('Operator')
    headers.push('Day Cumulative Footage')
    headers.push('Night Cumulative Footage')
    headers.push('Day Average ROP')
    headers.push('Night Average ROP')
    headers.push('Time Period')

    let worksheetWells = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A' + rowIndex,
    })

    rowIndex++
    for (let i = 0; i < data.length; i++) {
      let wellInfo = []
      wellInfo.push(data[i].wellName)
      wellInfo.push(data[i].rig)
      wellInfo.push(data[i].jobNumber)
      wellInfo.push(data[i].state)
      wellInfo.push(data[i].county)
      wellInfo.push(data[i].operator)
      wellInfo.push(data[i].days[data[i].days.length - 1].day.cumulativeFootage)
      wellInfo.push(data[i].days[data[i].days.length - 1].night.cumulativeFootage)
      wellInfo.push(data[i].days[data[i].days.length - 1].day.averageRop)
      wellInfo.push(data[i].days[data[i].days.length - 1].night.averageRop)
      wellInfo.push(shiftRef.current)
      XLSX.utils.sheet_add_aoa(worksheetWells, [wellInfo], { origin: 'A' + rowIndex })

      rowIndex++
    }

    XLSX.utils.book_append_sheet(wb, worksheetWells, 'Tower Comparison Kpi')

    for (let i = 0; i < data.length; i++) {
      rowIndex = 1
      let headerDays = []
      headerDays.push('Well Name')
      headerDays.push('Date')
      headerDays.push('Day Footage')
      headerDays.push('Night Footage')
      headerDays.push('Day Average ROP')
      headerDays.push('Night Average ROP')
      headerDays.push('Day Cumulative Footage')
      headerDays.push('Night Cumulative Footage')
      headerDays.push('Day Average ROP')
      headerDays.push('Night Average ROP')
      let worksheetDays = XLSX.utils.aoa_to_sheet([headerDays], {
        origin: 'A' + rowIndex,
      })
      rowIndex++
      for (let j = 0; j < data[i].days.length; j++) {
        let days = []
        days.push(data[i].wellName)
        days.push(data[i].days[j].date)
        days.push(data[i].days[j].day.footage)
        days.push(data[i].days[j].night.footage)
        days.push(data[i].days[j].day.averageRop)
        days.push(data[i].days[j].night.averageRop)
        days.push(data[i].days[j].day.cumulativeFootage)
        days.push(data[i].days[j].night.cumulativeFootage)
        days.push(data[i].days[j].day.averageRop)
        days.push(data[i].days[j].night.averageRop)
        XLSX.utils.sheet_add_aoa(worksheetDays, [days], { origin: 'A' + rowIndex })
        rowIndex++
      }
      XLSX.utils.book_append_sheet(wb, worksheetDays, data[i].jobNumber)
    }
    XLSX.writeFile(wb, fileName, { cellStyles: true, bookImages: true })
  }

  const updateShift = () => {
    if (shiftRef.current === 'six to six') {
      shiftRef.current = 'midnight to midnight'
    } else {
      shiftRef.current = 'six to six'
    }
    fetchData()
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const columnDefs = [
    {
      headerName: '',
      field: '',
      autoHeight: true,
      minWidth: 60,
      maxWidth: 60,
      cellRenderer: 'agGroupCellRenderer',
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      headerName: 'Well Name',
      colId: 'wellName',
      field: 'wellName',
      cellStyle: centerAlignCell,
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      headerName: 'Rig',
      colId: 'rig',
      field: 'rig',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Job#',
      colId: 'jobNumber',
      field: 'jobNumber',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'State',
      colId: 'state',
      field: 'state',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'County',
      colId: 'county',
      field: 'county',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Operator',
      colId: 'operator',
      field: 'operator',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Day Cumulative Footage',
      colId: 'dayCumulativeFootage',
      field: 'dayCumulativeFootage',
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        return numberWithCommasDecimals(params.data?.days[params.data?.days.length - 1]?.day?.cumulativeFootage, 2)
      },
    },
    {
      headerName: 'Night Cumulative Footage',
      colId: 'nightCumulativeFootage',
      field: 'nightCumulativeFootage',
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        return numberWithCommasDecimals(params.data?.days[params.data?.days.length - 1]?.night?.cumulativeFootage, 2)
      },
    },
    {
      headerName: 'Day Average ROP',
      colId: 'dayAverageRop',
      field: 'dayAverageRop',
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        return numberWithCommasDecimals(params.data?.days[params.data?.days.length - 1]?.day?.averageRop, 2)
      },
    },
    {
      headerName: 'Night Average ROP',
      colId: 'nightAverageRop',
      field: 'nightAverageRop',
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        return numberWithCommasDecimals(params.data?.days[params.data?.days.length - 1]?.night?.averageRop, 2)
      },
    },
    {
      headerName: 'Time Period',
      colId: 'timePeriod',
      field: 'timePeriod',
      cellStyle: { textAlign: 'start' },
    },
  ]

  const DetailCellRenderer = (params) => {
    return (
      <Box sx={{ width: '100%', height: 400, padding: '5px' }}>
        <TowercomparisonChart data={params.data} getUnitsText={getUnitsText} showCumulative={showCumulative} />
      </Box>
    )
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('towerComparisonKpi', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          onXlsxExport()
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={data}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'inventoryKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            masterDetail={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            detailCellRenderer={DetailCellRenderer}
            headerHeight={30}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
            {
              icon: (
                <Iconify icon='fluent:shifts-activity-24-regular' color={'green'} style={{ width: 28, height: 28 }} />
              ),
              name: `Shift ${shiftRef.current}`,
              onClick: updateShift,
            },
            {
              icon: (
                <Iconify
                  icon='fluent-mdl2:total'
                  color={showCumulative ? 'green' : 'red'}
                  style={{ width: 28, height: 28 }}
                />
              ),
              name: showCumulative ? 'Hide Cumulative' : 'Show Cumulative',
              onClick: () => {
                setShowCumulative(!showCumulative)
              },
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default TowerComparisonKpiGrid
